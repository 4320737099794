import React from "react"
import {graphql} from "gatsby"
import link from "../assets/images/link.svg";
import hexagon from "../assets/images/hexagon-blue.svg";
import arrow from "../assets/images/tail-down.svg";

import Layout from "../components/layout"
import SEO from "../components/seo"

const BlogIndex = ({data, location}) => {
    const siteTitle = data.site.siteMetadata?.title || `Title`
    const posts = data.allMarkdownRemark.nodes;

    if (posts.length === 0) {
        return (
            <Layout location={location} title={siteTitle}>
                <SEO title="All posts"/>
                <p>
                    No blog posts found. Add markdown posts to "content/blog" (or the
                    directory you specified for the "gatsby-source-filesystem" plugin in
                    gatsby-config.js).
                </p>
            </Layout>
        )
    }

    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="GDPR bírságok Magyarországon" description={"A magyarországi GDPR bírságok és büntetések listája és rövid ismertetője"}/>
            <section className="postList container">
                <div className="lineBox">
                    <span className="line-5">{null}</span>
                </div>
                <div className="flex floatingScrollDown" id="scrollDownToFooter">
                    <img className="hexagon" src={hexagon} />
                    <div className="content">
                        <p>És Ön felkészült?</p>
                        <img className="arrow" src={arrow} />
                    </div>
                </div>

                {posts.map(post => {
                    const title = post.frontmatter.title || post.fields.slug;

                    return (
                            <article key={post.fields.slug} className="flex">
                                <div className="details">
                                        <p className="date">{post.frontmatter.date}</p>
                                        <p className="event">{post.frontmatter.subject}</p>
                                        <div className="link flex">
                                            <img src={link} />
                                            <a target="_blank" className={"hatarozat"} href={post.frontmatter.link}><b>Határozat</b></a>
                                        </div>
                                </div>
                                <div className="articleBody">
                                    <h3 className={"amount"}>{post.frontmatter.amount}</h3>
                                    <div className="column4">
                                        <div dangerouslySetInnerHTML={{
                                            __html: post.html
                                        }}>
                                        </div>
                                    </div>
                                    <div className={"claim"}>
                                        <p>
                                            <b>Megsértett jog:</b>
                                            <br>{null}</br>
                                            {post.frontmatter.claim}
                                        </p>
                                    </div>
                                </div>
                            </article>
                    )
                })}
            </section>
        </Layout>
    )
};

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt,
        html,
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY.MM.DD"),
          title,
          description,
          subject,
          link,
          claim,
          amount
        }
      }
    }
  }
`
